<template>
    <b-row>
      <b-col
        xl="12"
      >
        <h5 class="mb-2">BAP Pelapor/Saksi/Ahli</h5>
        <b-form-group
          label="Pemeriksaan"
          label-for="Pemeriksaan"
        >
          <v-select
            v-model="localData.bap_pelapor.sebagai"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="pemeriksaanOptions"
            :disabled="true"
            value="Pelapor/Saksi/Ahli"
          />
        </b-form-group>

        <b-form-group
          label="Tgl Pemeriksaan"
          label-for="tanggal"
        >
          <b-form-datepicker id="tanggal" v-model="localData.bap_pelapor.tanggal" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>

        <b-form-group
          label="Waktu"
          label-for="waktu"
        >
              <b-form-timepicker v-model="localData.bap_pelapor.waktu" locale="id"></b-form-timepicker>
        </b-form-group>
        <b-form-group
          label="Tempat"
          label-for="tempat"
        >
          <b-form-input
            id="tempat"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.tempat"
          />
        </b-form-group>
        <b-form-group
          label="Tenaga Ahli"
          label-for="tenaga_ahli"
        >
          <b-form-input
            id="tenaga_ahli"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.tenaga_ahli"
          />
        </b-form-group>
      </b-col>
      <b-col xl="12">
        <h5 class="mb-2 mt-2">SURAT TUGAS SEKJEN KY RI</h5>
        <b-form-group
          label="No Surat"
          label-for="no_surat_tugas_sekjen"
        >
          <b-form-input
            id="no_surat_tugas_sekjen"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.no_surat_tugas_sekjen"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Surat"
          label-for="tgl_surat_tugas_sekjen"
        >
          <b-form-datepicker id="tgl_surat_tugas_sekjen" v-model="localData.bap_pelapor.tgl_surat_tugas_sekjen" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>
        <b-form-group
          label="Pemeriksa"
          label-for="Pemeriksa"
        >
          <b-form-input
            id="Pemeriksa"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.pemeriksa"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="12"
        class="mt-2"
      >
        <h5 class="mb-2 mt-2">SURAT TUGAS KEPALA BIRO WASKIM</h5>
        <b-form-group
          label="No Surat"
          label-for="No Surat"
        >
          <b-form-input
            id="No Surat"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.no_surat_tugas_kabiro"
          />
        </b-form-group>
        <b-form-group
          label="Tgl Surat"
          label-for="tgl_surat_tugas_kabiro"
        >
          <b-form-datepicker id="mulai" v-model="localData.bap_pelapor.tgl_surat_tugas_kabiro" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col
        xl="12"
        class="mt-2"
      >
        <h5 class="mb-2 mt-2">TERPERIKSA Pelapor/Saksi/Ahli</h5>
        <b-form-group
          label="Nama"
          label-for="nama"
        >
          <b-form-input
            id="nama"
            autofocus
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.nama"
          />
        </b-form-group>
        <b-form-group
          label="NIK/NIP"
          label-for="NIK"
        >
          <b-form-input
            id="NIK"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.nip"
          />
        </b-form-group>
        <b-form-group
          label="Tempat Lahir"
          label-for="Tempat"
        >
          <b-form-input
            id="Tempat"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.tempat_lahir"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Lahir"
          label-for="Tanggal Lahir"
        >
          <b-form-datepicker id="tgl_lahir" v-model="localData.bap_pelapor.tgl_lahir" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>
        <b-form-group
          label="Agama"
          label-for="Agama"
        >
          <b-form-input
            id="Agama"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.agama"
          />
        </b-form-group>
        <b-form-group
          label="Pekerjaan"
          label-for="Pekerjaan"
        >
          <b-form-input
            id="Pekerjaan"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.pekerjaan"
          />
        </b-form-group>
         <b-form-group
          label="Alamat"
          label-for="Alamat"
        >
          <b-form-textarea
            id="Alamat"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.alamat"
          />
        </b-form-group>
         <b-form-group
          label="No Telp"
          label-for="No Telp"
        >
          <b-form-input
            id="No Telp"
            trim
            placeholder=""
            type="number"
            
            v-model="localData.bap_pelapor.no_telp"
          />
        </b-form-group>
         <b-form-group
          label="Pendamping"
          label-for="Pendamping"
        >
          <b-form-input
            id="Pendamping"
            trim
            placeholder=""
            
            v-model="localData.bap_pelapor.pendamping"
          />
        </b-form-group>
      </b-col>
      <b-col xl="12">
        <pertanyaan-jawaban :pemeriksaanId="localData.bap_pelapor.id" />
      </b-col>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import {
  updateVerifikasi,
} from '@/connection-api/master'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KasusPosisi from '@/views/kasus-posisi/list/List.vue'
import PokokLaporan from '@/views/pokok-laporan/list/List.vue'
import DataPendukung from '@/views/data-pendukung/list/List.vue'
import Analisis from '@/views/analisis/list/List.vue'
import Kesimpulan from '@/views/kesimpulan/list/List.vue'
import SaranAnalisis from '@/views/saran-analisis/list/List.vue'
import TandaTangan from '@/views/tanda-tangan/list/List.vue'
import PertanyaanJawaban from '@/views/pertanyaan-jawaban/list/List.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    KasusPosisi,
     BFormCheckbox,
    BTab,
    BTabs,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    PokokLaporan,
    DataPendukung,
    Analisis,
    Kesimpulan,
    SaranAnalisis,
    TandaTangan,
    BFormDatepicker,
    BFormTimepicker,
    PertanyaanJawaban,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
    pemeriksaanOptions: {},
  },
  methods: {
    async update(params) {
      switch (params) {
        case 'investigasi-advokasi':
          this.localData.jenis_cta = "investigasi-advokasi";
          break;
        case 'pemeriksaan-pendahuluan':
          this.localData.jenis_cta = 'investigasi-advokasi';
          break;
      }
      
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('refetchData')
              this.$router.push({name: 'tpl-analisis-laporan'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
